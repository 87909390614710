import React, { useState, useEffect } from 'react';
import './flooring-category.css';
import floor1 from '../../../assets/floor1.jpeg';
import floor2 from '../../../assets/floor2.jpeg';
import floor3 from '../../../assets/floor3.jpeg';
import floor4 from '../../../assets/floor4.jpeg';

const FlooringCategory = () => {
  const images = [floor1, floor2, floor3, floor4];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true); // Start fade-out
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIsFading(false); // Start fade-in
      }, 400); // Delay matches fade-out duration
    }, 3000); // Change image every 2 seconds
    return () => clearInterval(interval); // Cleanup on component unmount
  }, [images.length]);

  return (
    <div className="main-container">
      {/* First Div with Content */}
      <div className="content-div">
        <h1 className="big-heading">Elegant Flooring for Every Step</h1>
        <hr className="underline" />
        <h3 className="sub-heading">Floor Runners, Carpets, Mats & More – Perfect for Every Corner</h3>
        <p className="description">
          Transform your home into a haven of style and comfort with our exquisite flooring collection. From elegant carpets and versatile floor runners to durable door mats and cozy floor mats, we have everything to suit your decor needs. Each product combines superior craftsmanship with premium materials, ensuring your spaces not only look stunning but feel inviting.
        </p>
        <button className="cta-button">Explore</button>
      </div>

      {/* Second Div with Image Carousel */}
      <div className="image-div">
        <img
          className={`centered-image ${isFading ? 'fade' : ''}`}
          src={images[currentImageIndex]}
          alt="Flooring example"
        />
      </div>
    </div>
  );
};

export default FlooringCategory;
