import React from "react";
import './collection-cards.css'; // Import CSS for styling
import dinnerwareImage from '../../../assets/dinnerware.jpeg'; // Example image
import kitchenStorageImage from '../../../assets/kitchenstorage.jpeg'; // Example image
import barwareImage from '../../../assets/barware.jpeg'; // Example image
import cupsImage from '../../../assets/cups.jpeg'; // Example image

const CollectionCards = () => {
  const collections = [
    {
      title: "Luxe Serveware",
      number: "001",
      description: "Fine porcelain, stoneware, and dinner sets.",
      image: dinnerwareImage,
    },
    {
      title: "Pantry Essentials",
      number: "002",
      description: "Organizers, containers, and culinary tools.",
      image: kitchenStorageImage,
    },
    {
      title: "Cocktail Glasses",
      number: "003",
      description: "Cocktail glasses, decanters, and bar tools.",
      image: barwareImage,
    },
    {
      title: "Ceramic Cups",
      number: "004",
      description: "Elegant designs for your coffee and tea moments.",
      image: cupsImage,
    },
  ];

  return (
    <div className="collection-card-container">
      {collections.map((collection, index) => (
        <div className="collection-card" key={index}>
          <div className="card-image-container">
            <img src={collection.image} alt={collection.title} className="card-image" />
          </div>
          <div className="card-content">
            <h2 className="card-title">{collection.title}</h2>
            <p className="card-number">NR {collection.number}</p>
            <p className="card-description">{collection.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CollectionCards;
