import React from 'react';
import './collection-slider.css';

const CollectionsPage = () => {
    return (
      <div class="collection-slider-container">
      <div class="collection-slider">
          <div class="collection" id="bed-runners">
            <span>BED RUNNERS</span>
            <div className="overlay">
              
            </div>
          </div>
          <div class="col1">
              <div class="collection" id="mattress-protectors">
                <span>MATTRESS PROTECTORS</span>
                  <div class="overlay">
                      
                  </div>
              </div>
              <div class="collection" id="bedsheets">
                      <span>BEDSHEETS</span>
                  <div class="overlay">
                      
                  </div>
              </div>
          </div>
          <div class="col2">
              <div class="collection" id="bedding-sets">
                      <span>BEDDING SETS</span>
                  <div class="overlay">
                      
                  </div>
              </div>
              <div class="collection" id="blankets-quilts-dohars">
                      <span>BLANKETS, QUILTS & DOHARS</span>
                  <div class="overlay">
                      
                  </div>
              </div>
              
              <div class="collection" id="pillows-covers">
                      <span>PILLOWS & PILLOW COVERS</span>
                  <div class="overlay">
                      
                  </div>
              </div>
          </div>
          
          <div class="collection" id="bed-covers">
                  <span>BED COVERS</span>
              <div class="overlay">
                  
              </div>
          </div>
          <div class="col3">
              <div class="collection" id="diwan-sets">
                      <span>DIWAN SETS</span>
                  <div class="overlay">
                      
                  </div>
              </div>
              <div class="collection" id="chair-pads-covers">
                      <span>CHAIR PADS & COVERS</span>
                  <div class="overlay">
                      
                  </div>
              </div>
          </div>
          <div class="collection" id="sofa-covers">
                  <span>SOFA COVERS</span>
              <div class="overlay">
                  
              </div>
          </div>
      </div>
  </div>
    );
};

export default CollectionsPage;
