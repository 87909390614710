import React, { useState, useEffect, useRef } from 'react';
import {
  Search as SearchIcon,
  Person3Outlined as ProfileIcon,
  ShoppingCart as ShoppingCartIcon,
  Favorite as HeartIcon,
  ExpandMore as ArrowDropDownIcon
} from '@mui/icons-material';
import './cust_header.css';
import logo from '../../../assets/logo/logo1.png';
import Clothing from '../../../assets/clothing.jpeg';
import Clothing1 from '../../../assets/clothing1.jpg';

const CustHeader = () => {
  const [activeCollection, setActiveCollection] = useState('');
  const [megaMenuVisible, setMegaMenuVisible] = useState(false);
  const megaMenuRef = useRef(null);
  const timeoutRef = useRef(null);

  const collections = {
    STYLING: {
      categories1: ['Bed Runners', 'Mattress Protectors', 'Bedsheets', 'Bedding Sets', 'Blankets, Quilts & Dohars', 'Pillows & Pillow Covers', 'Bed Covers', 'Diwan Sets', 'Chair Pads & Covers', 'Sofa Covers'],
      categories2: ['Wall Decor', 'Festive Decor', 'Wall Shelves', 'Showpieces & Vases', 'Cushions & Cushion Covers', 'Curtains'],
      categories3: ['Table Runners', 'Dinnerware & Serveware', 'Cups & Mugs', 'Bakeware & Cookware', 'Kitchen Storage & Tools', 'Bar & Drinkware', 'Table Covers & Furnishings'],
      categories4: ['Floor Runners', 'Carpets', 'Floor Mats & Dhurries', 'Door Mats'],
      image: Clothing,
      image1: Clothing1
    },
    ESSENTIAL: {
      categories1: ['Bath Towels', 'Hand & Face Towels', 'Beach Towels', 'Twoels Set', 'Bath Rugs', 'Bath Robes', 'Bathroom Accessories', 'Shower Curtains'],
      categories2: ['Bins', 'Hangers', 'Organisers', 'Hooks & Holders', 'Laundry Bags'], // Only 2 categories for Essentials
      image: Clothing,
      image1: Clothing1
    }
  };

  const showMegaMenu = (collection) => {
    clearTimeout(timeoutRef.current); // Clear any existing timeout to prevent premature hide
    setActiveCollection(collection);
    setMegaMenuVisible(true);
  };

  const hideMegaMenu = () => {
    // Use a delay to ensure smooth transition
    timeoutRef.current = setTimeout(() => {
      setMegaMenuVisible(false);
      setActiveCollection('');
    }, 200); // Adjust as needed for smooth transition
  };

  const handleClickOutside = (event) => {
    if (megaMenuRef.current && !megaMenuRef.current.contains(event.target)) {
      hideMegaMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      clearTimeout(timeoutRef.current); // Clear timeout on unmount
    };
  }, []);

  const handleCollectionMouseEnter = (collection) => {
    clearTimeout(timeoutRef.current); // Clear any existing timeout
    showMegaMenu(collection);
  };

  const handleMegaMenuMouseEnter = () => {
    clearTimeout(timeoutRef.current); // Clear any existing timeout
  };

  const handleMouseLeave = () => {
    hideMegaMenu();
  };

  const renderMegaMenu = () => {
    if (!activeCollection) return null;
    const { categories1, categories2, categories3, categories4, image, image1 } = collections[activeCollection];

    return (
      <div
        className={`mega-menu ${megaMenuVisible ? 'active' : ''}`}
        ref={megaMenuRef}
        onMouseEnter={handleMegaMenuMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="mega-menu-content">
          <div className="categories1">
            <h3>{activeCollection === 'STYLING' ? 'Bed Linen & Furnishing' : 'Bath'}</h3>
            {categories1.map((category, index) => (
              <div key={index}>{category}</div>
            ))}
          </div>
          <div className="categories2">
            <h3>{activeCollection === 'STYLING' ? 'Home Decor' : 'Storage'}</h3>
            {categories2.map((category, index) => (
              <div key={index}>{category}</div>
            ))}
          </div>
          {categories3 && (
            <div className="categories3">
              <h3>{activeCollection === 'STYLING' ? 'Kitchen & Table' : 'Explore more'}</h3>
              {categories3.map((category, index) => (
                <div key={index}>{category}</div>
              ))}
            </div>
          )}
          {categories4 && (
            <div className="categories4">
              <h3>{activeCollection === 'STYLING' ? 'Flooring' : 'Discover More'}</h3>
              {categories4.map((category, index) => (
                <div key={index}>{category}</div>
              ))}
            </div>
          )}
          <div className="menu-image">
            <img src={image} alt={`${activeCollection} image`} width={250} />
            <img src={image1} alt={`${activeCollection} image1`} width={250} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <header className="cust-header">
      <div className="cust-header-left">
        <a href="/" className="logo">
          <img src={logo} alt="HomeDecor" />
        </a>
      </div>
      <div className="cust-header-center">
        <div className="collection-menu">
          <a href="/" className="collection-link">SHOP</a>
          <div
            className="collection-link-with-icon"
            onMouseEnter={() => handleCollectionMouseEnter('STYLING')}
            onMouseLeave={handleMouseLeave}
          >
            <a href="/clothing" className="collection-link">HOME STYLING</a>
          </div>
          <div
            className="collection-link-with-icon"
            onMouseEnter={() => handleCollectionMouseEnter('ESSENTIAL')}
            onMouseLeave={handleMouseLeave}
          >
            <a href="/clothing" className="collection-link">ESSENTIAL SPACES</a>
          </div>
          <a href="/about" className="collection-link">ABOUT</a>
          <a href="/contact" className="collection-link">CONTACT US</a>
        </div>
      </div>
      <div className="cust-header-right">
        <div className="search-bar">
          <SearchIcon style={{ fontSize: 20, color: '#888' }} className="search-icon" />
          <input type="text" placeholder="Search..." className="search-input" />
        </div>
        <a href="/profile" className="header-link">
          <ProfileIcon style={{ fontSize: 24 }} className="header-icon" />
        </a>
        <a href="/cart" className="header-link">
          <ShoppingCartIcon style={{ fontSize: 24 }} className="header-icon" />
        </a>
      </div>

      {renderMegaMenu()}
    </header>
  );
};

export default CustHeader;
