import React from 'react';
import './homepgelement2.css';
import bath1 from '../../../assets/bath1.jpg';
import bath2 from '../../../assets/bath2.jpg';

const Homepgelement2 = () => {
  return (
    <div className="features-section">
      {/* First Image */}
      <div className="feature-item image-item">
        <img
          className="feature-image"
          src={bath1}
          alt="Premium Quality"
        />
      </div>

      {/* Second Image */}
      <div className="feature-item image-item">
        <img
          className="feature-image"
          src={bath2}
          alt="Eco-Friendly"
        />
      </div>

      {/* First Content */}
      <div className="feature-item content-item">
        <h3>Premium Quality Decor</h3>
        <p>Our products are crafted with the finest materials to ensure long-lasting elegance and durability.</p>
      </div>

      {/* Second Content */}
      <div className="feature-item content-item">
        <h3>Affordable Elegance</h3>
        <p>Transform your space without breaking the bank. We offer stylish options for every budget.</p>
      </div>

      {/* Third Content */}
      <div className="feature-item content-item">
        <h3>Tailored to Your Style</h3>
        <p>Customize your home decor to perfectly match your unique taste and personality.</p>
      </div>
    </div>
  );
};

export default Homepgelement2;
