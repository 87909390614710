import React from 'react';
import './home-decor-col.css';
import wall from '../../../assets/walldecor.jpeg';
import vases from '../../../assets/vases.jpeg';
import festive from '../../../assets/hd-video1.mp4';
import shelves from '../../../assets/shelves.jpg';
import curtains from '../../../assets/curtains.jpg';
import cushions from '../../../assets/hd-video2.mp4';
import TextContent from './test-content'

const HomeDecor = () => {
    return (
      <div className="home-decor-col"> 
        <TextContent />
        <div className="home-decor-container">
  
          <div className="section-one">
            {/* First Sub-section: Two Portrait Images */}
            <div className="sub-section portrait-group">
              <div className="media portrait-image" data-text="Wall Decor">
                <img src={wall} alt="Wall Decor" />
              </div>
              <div className="media portrait-image" data-text="Elegant Vases">
                <img src={vases} alt="Elegant Vases" />
              </div>
            </div>
  
            {/* Second Sub-section: Landscape Image */}
            <div className="sub-section landscape-image1">
              <div className="media" data-text="Modern Shelves">
                <img src={shelves} alt="Modern Shelves" />
              </div>
            </div>
          </div>
  
          <div className="section-two">
            {/* First Sub-section: Landscape Image */}
            <div className="sub-section landscape-image2">
              <div className="media" data-text="Luxury Curtains">
                <img src={curtains} alt="Luxury Curtains" />
              </div>
            </div>
  
            {/* Second Sub-section: Portrait Videos */}
            <div className="sub-section portrait-content">
              <div className="media portrait-video" data-text="Comfy Cushions">
                <video src={cushions} autoPlay muted loop />
              </div>
              <div className="media portrait-video" data-text="Festive Decor">
                <video src={festive} autoPlay muted loop />
              </div>
            </div>
          </div>
  
        </div>
      </div>
    );
  };
  

export default HomeDecor;
