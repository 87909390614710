import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './image-slider.css'; // Import the CSS file
import Banner1 from '../../../assets/offer1.png'; 
import Banner2 from '../../../assets/offer2.png';
import Banner3 from '../../../assets/offer3.png';
import Banner4 from '../../../assets/offer4.png';

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    adaptiveHeight: true,
    arrows: false, // Disable next and back icons
  };

  return (
    <div className="image-slider-container">
      <Slider {...settings}>
        <div className="slide">
          <img src={Banner1} alt="Offer 1" className="slider-image" />
        </div>
        <div className="slide">
          <img src={Banner2} alt="Offer 2" className="slider-image" />
        </div>
        <div className="slide">
          <img src={Banner3} alt="Offer 3" className="slider-image" />
        </div>
        <div className="slide">
          <img src={Banner4} alt="Offer 4" className="slider-image" />
        </div>
      </Slider>
    </div>
  );
};

export default ImageSlider;
