import React, { useState, useEffect } from 'react';
import slide1 from '../../../assets/slide3.jpg';
import './homepgelement1.css';

const phrases = ['Enhance Your Space.', 'Define Your Style.', 'Elevate Your Home.'];

const Homepgelement1 = () => {
  const [currentText, setCurrentText] = useState('');
  const [index, setIndex] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const typingSpeed = 150;
  const deletingSpeed = 100;

  useEffect(() => {
    const handleTyping = () => {
      const fullText = phrases[loopNum % phrases.length];
      if (!deleting) {
        setCurrentText(fullText.substring(0, index + 1));
        setIndex(index + 1);
        if (index + 1 === fullText.length) {
          setDeleting(true);
        }
      } else {
        setCurrentText(fullText.substring(0, index - 1));
        setIndex(index - 1);
        if (index === 0) {
          setDeleting(false);
          setLoopNum(loopNum + 1);
        }
      }
    };

    const timer = setTimeout(
      handleTyping,
      deleting ? deletingSpeed : typingSpeed
    );

    return () => clearTimeout(timer);
  }, [index, deleting, loopNum]);

  return (
    <div
      className="homepgelement1"
      style={{
        backgroundImage: `url(${slide1})`,
      }}
    >
      <div className="img-overlay"></div>

      {/* Content Section */}
      <div className="content">
        <div className="content-title-container">
          <div className="border-line"></div>
          <h1 className="content-title">DECOR'</h1>
          <h3 className="content-subtitle">{currentText}</h3>
          <div className="border-line"></div>
        </div>
      </div>
    </div>
  );
};

export default Homepgelement1;
