import React from 'react';
import './circular-collection.css';

// Importing images
import bedImage from '../../../assets/bed.jpeg';
import homeDecorImage from '../../../assets/home.jpeg';
import kitchenImage from '../../../assets/kitchen.jpeg';
import flooringImage from '../../../assets/floor.jpeg';
import bathImage from '../../../assets/bath.jpeg';
import storageImage from '../../../assets/storage.jpeg';

const categories = [
  { name: 'Bed & Furnishing', image: bedImage },
  { name: 'Home Decor', image: homeDecorImage },
  { name: 'Kitchen & Table', image: kitchenImage },
  { name: 'Flooring', image: flooringImage },
  { name: 'Bath', image: bathImage },
  { name: 'Storage', image: storageImage },
];

const CircularCollection = () => {
  return (
    <div className="collection-container">
      {categories.map((category, index) => (
        <div className="collection-item" key={index}>
          <div className="circle-wrapper">
            <div
              className="circle"
              style={{ backgroundImage: `url(${category.image})` }}
            ></div>
          </div>
          <p className="category-name">{category.name}</p>
        </div>
      ))}
    </div>
  );
};

export default CircularCollection;
