import React from 'react';
import './wall-of-fame.css';

// Import images from the assets folder
import f1 from '../../../assets/f1.jpeg';
import f2 from '../../../assets/f2.jpeg';
import f3 from '../../../assets/f3.jpeg';
import f4 from '../../../assets/f4.jpeg';
import f5 from '../../../assets/f5.jpeg';
import f6 from '../../../assets/f6.jpeg';
import f7 from '../../../assets/f7.jpeg';
import f8 from '../../../assets/f8.jpeg';
import f9 from '../../../assets/f9.jpeg';
import f10 from '../../../assets/f10.jpeg';

const WallOfFame = () => {
    const images = [f1, f2, f3, f4, f5, f6, f7, f8, f9, f10];

    // Define border radii for different images
    const borderRadiusStyles = [
        '50%',     // Circle
        '15%',     // Rounded corners
        '30%',     // Rounded corners
        '5px',     // Slightly rounded
        '50%',     // Circle
        '15%',     // Rounded corners
        '10%',     // Rounded corners
        '20%',     // Rounded corners
        '30%',     // Rounded corners
        '50%'      // Circle
    ];

    return (
        <div className="wall-of-fame">
            <h2 className="wall-heading">The Epitome of Elegance</h2>
            <p className="subtitle">Where Style Meets Sophistication – The Seal of a Happy Home</p>
            <div className="fame-container">
                {images.map((src, index) => (
                    <div
                        className="fame-item"
                        key={index}
                        style={{ borderRadius: borderRadiusStyles[index], border: `4px solid #596e79` }} // Adding a border with same border-radius
                    >
                        <img src={src} alt={`Celeb ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WallOfFame;
