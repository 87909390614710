// src/pages/customer/homepage/homepage.jsx
import React from 'react';
import './homepage.css';
import CustHeader from '../../../components/customer/header/cust_header';
import MenuBar from '../../../components/customer/menubar/menubar.jsx'
import Footer from '../../../components/customer/footer/cust_footer.jsx'
import CircularCollection from '../../../components/customer/homepgelements/circular-collection.jsx';
import Homepgelement1 from '../../../components/customer/homepgelements/homepgelement1.jsx';
import Homepgelement2 from '../../../components/customer/homepgelements/image-slider.jsx';
import Homepgelement3 from '../../../components/customer/homepgelements/wall-of-fame.jsx';
import Homepgelement4 from '../../../components/customer/homepgelements/home-decor-col.jsx';
import Homepgelement5 from '../../../components/customer/homepgelements/homepgelement2.jsx';
import Homepgelement6 from '../../../components/customer/homepgelements/flooring-category.jsx';
import Homepgelement7 from '../../../components/customer/homepgelements/collection-cards.jsx';
import Homepgelement8 from '../../../components/customer/homepgelements/collection-slider.jsx';

import Collections from '../../../assets/collections.png';
import Products from '../../../assets/products.png';
import Products2 from '../../../assets/products2.png';

function HomePage() {
  return (
<>
  <CustHeader/>
  {/* <MenuBar/> */}
  < CircularCollection/>
  <div className="homepgelement1">
    <Homepgelement1/>
  </div>
  <div className="homepgelement2">
    <Homepgelement2/>
  </div>
  <div className="homepgelement3">
    <Homepgelement3/>
  </div>
  <div className="homepgelement4">
    <Homepgelement4/>
  </div>
  <div className="homepgelement5">
    <Homepgelement5/>
  </div>
  <div className="homepgelement6">
    <Homepgelement6/>
  </div>
  <div className="homepgelement7">
    <Homepgelement7/>
  </div>
  <div className="homepgelement8">
    <Homepgelement8/>
  </div>
  
  <Footer />
</>
  );
}

export default HomePage;
