import React from 'react';
import './text-content.css';

const TextContent = () => {
  return (
    <div className="text-content-section">
      <div className="heading-group">
        <h1 className="main-heading">Antic</h1>
      </div>
      <div className="content-group">
        <h3 className="sub-heading">Discover Unique Home Decor</h3>
        <p className="small-paragraph">
          Transform your living space with our curated selection of home decor items. From timeless antiques to modern designs, find pieces that reflect your personality and style.
        </p>
      </div>
    </div>
  );
};

export default TextContent;
