import React from 'react';
import './about-us.css';
import CustHeader from '../../../components/customer/header/cust_header';

const AboutUs = () => {
  return (
        <div className="about-us-container">
            <CustHeader/>
        </div>
  );
};

export default AboutUs;
